import React, { Component } from 'react';
import axiosMenuscreen from '../../axios-menuscreen';
import axios from 'axios';

import FoodItem from './FoodItem/FoodItem';
import WineItem from '../WineItems/WineItem/WineItem';
import SakeItem from '../SakeItems/SakeItem/SakeItem';

import classes from './FoodItems.module.scss';

const IS_DEBUG_MODE_ACTIVE = false;

const now = new Date(),
    year = now.getFullYear();

let month = now.getMonth() + 1;
month = month.toString().length === 1 ? '0' + month : month;

const day = now.getDate().toString().length === 1 ? '0' + now.getDate() : now.getDate(),
    dateStr = year + '-' + month + '-' + day;

const wineTypeTags = ['rose', 'red_wine', 'white_wine', 'green_wine', 'espumante', 'licoroso'];
const wineRegionTags = ['all_regions', 'minho', 'transmontano_douro', 'beiras', 'lisboa', 'tejo', 'alentejo', 'algarve', 'setubal', 'madeira', 'acores', 'mundo'];
const sakeTypeTags = ['jummai', 'ginjo', 'daiginjo', 'honjozo'];
const drinkTags = ['drinks', 'water', 'beer', 'wines', 'sake', ...wineTypeTags, ...wineRegionTags, ...sakeTypeTags];

const DAILY_TTL = 2;
const REGULAR_TTL = 15;

let logMessages = [];

class FoodItems extends Component {
    state = {
        currentSectionId: null,
        items: [],
        filters: {
            wineRegion: null,
            wineType: null,
            sakeType: null,
            availableWineTypes: null,
            availableSakeTypes: null
        }
    };

    isLocalDataValid = (timestamp, ttl) => ((new Date()) - new Date(timestamp)) < (ttl * 60 * 1000);

    generateLog(message) {
        const d = new Date();
        const datestring = `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-`
            + `${("0" + d.getDate()).slice(-2)} ${("0" + d.getHours()).slice(-2)}:`
            + `${("0" + d.getMinutes()).slice(-2)}:${("0" + d.getSeconds()).slice(-2)}`;

        logMessages.push({
            uuid: localStorage.getItem('device_uuid'),
            timestamp: datestring,
            message: message
        });

        this.logAction();
    }

    logAction() {
        let failedLogs = [];

        const logs = logMessages.map(async (logMessage) => {
            axios.post('https://plataforma.ementasdigitais.pt/api/client_logs', {
                uuid: logMessage.uuid,
                timestamp: logMessage.timestamp,
                message: logMessage.message
            }, {
                    headers: {
                        'X-Auth-Token': localStorage.getItem('api_token')
                    }
                })
                .catch(() => {
                    failedLogs.push(logMessage);
                });
        });

        Promise.all(logs).then(() => {
            logMessages = failedLogs.slice();
        });
    }

    loadFoodItems() {
        if (this.props.filter) {
            const currLang = localStorage.getItem('lang') || 'pt';

            if (this.props.filter.category) {
                if (this.props.filter.category.id !== this.state.currentSectionId) {
                    this.setState((prevState => ({
                        filters: {
                            ...prevState.filters,
                            wineRegion: null,
                            wineType: null,
                            sakeType: null
                        }
                    })));

                    let currentData = localStorage.getItem(`items_${this.props.filter.category.id}_${currLang}`);
                    let currentDataTimestamp = localStorage.getItem(`items_${this.props.filter.category.id}_${currLang}_ts`);

                    if (currentData && currentDataTimestamp && this.isLocalDataValid(currentDataTimestamp, REGULAR_TTL)) {
                        window.scrollTo(0, 0);
                        console.warn('Serving from Cache');
                        this.setState({
                            currentSectionId: this.props.filter.category.id,
                            items: JSON.parse(localStorage.getItem(`items_${this.props.filter.category.id}_${currLang}`))
                        });
                        if (IS_DEBUG_MODE_ACTIVE) {
                            this.generateLog(`Items from category "${this.props.filter.category.id}"`
                                + ` with "${currLang}" language were loaded from cache. Data is less`
                                + ` than ${REGULAR_TTL} minutes old.`);
                        }
                    } else {
                        const apiUrl = (this.props.filter.type !== 'manual_managed' ?
                            '/category/' : '/sections/') + this.props.filter.category.id + '/items';

                        axiosMenuscreen.get(apiUrl)
                            .then(response => {
                                window.scrollTo(0, 0);
                                this.setState({
                                    currentSectionId: this.props.filter.category.id,
                                    items: response.data
                                });
                                localStorage.setItem(`items_${this.props.filter.category.id}_${currLang}`, JSON.stringify(response.data));
                                localStorage.setItem(`items_${this.props.filter.category.id}_${currLang}_ts`, new Date());
                                if (IS_DEBUG_MODE_ACTIVE) {
                                    this.generateLog(`Items from category "${this.props.filter.category.id}"`
                                        + ` with "${currLang}" language were loaded from API endpoint "${apiUrl}"`);
                                }
                            })
                            .catch(error => {
                                window.scrollTo(0, 0);
                                // Code 602 => Suspended
                                if (error.response && error.response.data &&
                                    error.response.data.code && error.response.data.code === 602) {
                                    if (IS_DEBUG_MODE_ACTIVE) {
                                        this.generateLog(`Account Suspended. Leaving webview`);
                                    }
                                    localStorage.clear();
                                    window.open(window.location + 'suspended', '_self', '');
                                } else {
                                    console.warn('Serving Offline Content');
                                    if (IS_DEBUG_MODE_ACTIVE) {
                                        this.generateLog(`Unable to reach API endpoint "${apiUrl}". Serving Offline content.`);
                                    }
                                    this.setState({
                                        currentSectionId: this.props.filter.category.id,
                                        items: JSON.parse(localStorage.getItem(`items_${this.props.filter.category.id}_${currLang}`)),
                                    });
                                }
                            });
                    }
                }
            } else {
                if (this.props.filter.is_daily_config && this.state.currentSectionId !== 0) {
                    this.setState(prevState => ({
                        filters: {
                            ...prevState.filters,
                            wineRegion: null,
                            wineType: null,
                            sakeType: null
                        }
                    }));

                    let currentData = localStorage.getItem(`items_today_${currLang}`);
                    let currentDataTimestamp = localStorage.getItem(`items_today_${currLang}_ts`);

                    if (currentData && currentDataTimestamp && this.isLocalDataValid(currentDataTimestamp, DAILY_TTL)) {
                        window.scrollTo(0, 0);
                        console.warn('Serving from Cache');
                        this.setState({
                            currentSectionId: 0,
                            items: JSON.parse(localStorage.getItem(`items_today_${currLang}`)),
                        });
                        if (IS_DEBUG_MODE_ACTIVE) {
                            this.generateLog(`Items from category "Schedule Items" with`
                                + ` "${currLang}" language were loaded`
                                + ` from cache. Data is less than ${DAILY_TTL} minutes old.`);
                        }
                    } else {
                        axiosMenuscreen.get('/scheduled_items/' + dateStr)
                            .then(response => {
                                window.scrollTo(0, 0);
                                this.setState({
                                    currentSectionId: 0,
                                    items: response.data
                                });
                                localStorage.setItem(`items_today_${currLang}`, JSON.stringify(response.data));
                                localStorage.setItem(`items_today_${currLang}_ts`, new Date());
                                if (IS_DEBUG_MODE_ACTIVE) {
                                    this.generateLog(`Items from category "Schedule Items" with "${currLang}" language`
                                        + ` were loaded from API endpoint "/schedule_items/${dateStr}"`);
                                }
                            })
                            .catch(error => {
                                window.scrollTo(0, 0);
                                // Code 602 => Suspended
                                if (error.response && error.response.data &&
                                    error.response.data.code && error.response.data.code === 602) {
                                    if (IS_DEBUG_MODE_ACTIVE) {
                                        this.generateLog(`Account Suspended. Leaving webview`);
                                    }
                                    localStorage.clear();
                                    window.open(window.location + 'suspended', '_self', '');
                                } else {
                                    console.warn('Serving Offline Content');
                                    if (IS_DEBUG_MODE_ACTIVE) {
                                        this.generateLog(`Unable to reach API endpoint "/schedule_items/${dateStr}". Serving Offline content.`);
                                    }
                                    this.setState({
                                        currentSectionId: 0,
                                        items: JSON.parse(localStorage.getItem(`items_today_${currLang}`)),
                                    });
                                }
                            });
                    }
                } else if (drinkTags.indexOf(this.props.filter.tag) >= 0 &&
                    this.props.filter.id !== this.state.currentSectionId) {
                    if (this.props.filter.tag === 'wines') {
                        this.setState(prevState => ({
                            filters: {
                                ...prevState.filters,
                                availableWineTypes: this.props.filter.children
                            }
                        }));
                    }
                    if(this.props.filter.tag === 'sake') {
                        this.setState(prevState => ({
                            filters: {
                                ...prevState.filters,
                                availableSakeTypes: this.props.filter.children
                            }
                        }));
                    }

                    if ([...wineTypeTags, ...wineRegionTags].indexOf(this.props.filter.tag) >= 0) {
                        window.scrollTo(0, 0);
                        if (wineTypeTags.indexOf(this.props.filter.tag) >= 0) {
                            this.setState(prevState => ({
                                filters: {
                                    ...prevState.filters,
                                    wineType: this.props.filter.tag,
                                    sakeType: null
                                }
                            }));
                        } else {
                            this.setState(prevState => ({
                                filters: {
                                    ...prevState.filters,
                                    wineRegion: this.props.filter.tag !== 'all_regions' ? this.props.filter.tag : null,
                                    sakeType: null
                                }
                            }));
                        }
                    } else if (sakeTypeTags.indexOf(this.props.filter.tag) >= 0) {
                        window.scrollTo(0, 0);
                        this.setState(prevState => ({
                            filters: {
                                ...prevState.filters,
                                wineRegion: null,
                                wineType: null,
                                sakeType: this.props.filter.tag
                            }
                        }));
                    } else {
                        this.setState((prevState => ({
                            filters: {
                                ...prevState.filters,
                                wineRegion: null,
                                wineType: null,
                                sakeType: null
                            }
                        })));

                        let currentData = localStorage.getItem(`items_${this.props.filter.id}_${currLang}`);
                        let currentDataTimestamp = localStorage.getItem(`items_${this.props.filter.id}_${currLang}_ts`);

                        if (currentData && currentDataTimestamp && this.isLocalDataValid(currentDataTimestamp, REGULAR_TTL)) {
                            window.scrollTo(0, 0);
                            console.warn('Serving from Cache');
                            this.setState({
                                currentSectionId: this.props.filter.id,
                                items: JSON.parse(localStorage.getItem(`items_${this.props.filter.id}_${currLang}`))
                            });

                            if (IS_DEBUG_MODE_ACTIVE) {
                                this.generateLog(`Items from category "${this.props.filter.id}"`
                                    + ` with "${currLang}" language were loaded from cache. Data`
                                    + ` is less than ${REGULAR_TTL} minutes old.`);
                            }
                        } else {
                            const apiUrl = (this.props.filter.type !== 'manual_managed' ? '/category/' : '/sections/') +
                                this.props.filter.id + '/items';
                            axiosMenuscreen.get(apiUrl)
                                .then(response => {
                                    window.scrollTo(0, 0);
                                    this.setState({
                                        currentSectionId: this.props.filter.id,
                                        items: response.data
                                    });
                                    localStorage.setItem(`items_${this.props.filter.id}_${currLang}`, JSON.stringify(response.data));
                                    localStorage.setItem(`items_${this.props.filter.id}_${currLang}_ts`, new Date());

                                    if (IS_DEBUG_MODE_ACTIVE) {
                                        this.generateLog(`Items from category "${this.props.filter.id}"`
                                            + ` with "${currLang}" language were loaded from API endpoint "${apiUrl}"`);
                                    }
                                })
                                .catch(error => {
                                    window.scrollTo(0, 0);
                                    // Code 602 => Suspended
                                    if (error.response && error.response.data &&
                                        error.response.data.code && error.response.data.code === 602) {
                                        if (IS_DEBUG_MODE_ACTIVE) {
                                            this.generateLog(`Account Suspended. Leaving webview`);
                                        }
                                        localStorage.clear();
                                        window.open(window.location + 'suspended', '_self', '');
                                    } else {
                                        console.warn('Serving Offline Content');
                                        if (IS_DEBUG_MODE_ACTIVE) {
                                            this.generateLog(`Unable to reach API endpoint "${apiUrl}". Serving Offline content.`);
                                        }
                                        this.setState({
                                            currentSectionId: this.props.filter.id,
                                            items: JSON.parse(localStorage.getItem(`items_${this.props.filter.id}_${currLang}`))
                                        });
                                    }
                                });
                        }
                    }
                } else if (!this.props.filter.is_daily_config && drinkTags.indexOf(this.props.filter.tag) < 0 && this.props.filter.id !== this.state.currentSectionId) {
                    this.setState((prevState => ({
                        filters: {
                            ...prevState.filters,
                            wineRegion: null,
                            wineType: null,
                            sakeType: null
                        }
                    })));

                    let currentData = localStorage.getItem(`items_${this.props.filter.id}_${currLang}`);
                    let currentDataTimestamp = localStorage.getItem(`items_${this.props.filter.id}_${currLang}_ts`);

                    if (currentData && currentDataTimestamp && this.isLocalDataValid(currentDataTimestamp, REGULAR_TTL)) {
                        window.scrollTo(0, 0);
                        console.warn('Serving from Cache');
                        this.setState({
                            currentSectionId: this.props.filter.id,
                            items: JSON.parse(localStorage.getItem(`items_${this.props.filter.id}_${currLang}`))
                        });
                        if (IS_DEBUG_MODE_ACTIVE) {
                            this.generateLog(`Items from category "${this.props.filter.id}"`
                                + ` with "${currLang}" language were loaded from cache. Data`
                                + ` is less than ${REGULAR_TTL} minutes old.`);
                        }
                    } else {
                        const apiUrl = (this.props.filter.type !== 'manual_managed' ? '/category/' : '/sections/') +
                            this.props.filter.id + '/items';
                        axiosMenuscreen.get(apiUrl)
                            .then(response => {
                                window.scrollTo(0, 0);
                                this.setState({
                                    currentSectionId: this.props.filter.id,
                                    items: response.data
                                });
                                localStorage.setItem(`items_${this.props.filter.id}_${currLang}`, JSON.stringify(response.data));
                                localStorage.setItem(`items_${this.props.filter.id}_${currLang}_ts`, new Date());
                                if (IS_DEBUG_MODE_ACTIVE) {
                                    this.generateLog(`Items from category "${this.props.filter.id}"`
                                        + ` with "${currLang}" language were loaded from API endpoint "${apiUrl}"`);
                                }
                            })
                            .catch(error => {
                                window.scrollTo(0, 0);
                                // Code 602 => Suspended
                                if (error.response && error.response.data &&
                                    error.response.data.code && error.response.data.code === 602) {
                                    if (IS_DEBUG_MODE_ACTIVE) {
                                        this.generateLog(`Account Suspended. Leaving webview`);
                                    }
                                    localStorage.clear();
                                    window.open(window.location + 'suspended', '_self', '');
                                } else {
                                    console.warn('Serving Offline Content');
                                    if (IS_DEBUG_MODE_ACTIVE) {
                                        this.generateLog(`Unable to reach API endpoint "${apiUrl}". Serving Offline content.`);
                                    }
                                    this.setState({
                                        currentSectionId: this.props.filter.id,
                                        items: JSON.parse(localStorage.getItem(`items_${this.props.filter.id}_${currLang}`))
                                    });
                                }
                            });
                    }
                }
            }
        }
    }

    componentDidMount() {
        this.loadFoodItems();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.filter !== this.props.filter ||
            nextState.items !== this.state.items ||
            nextState.filters.wineType !== this.state.filters.wineType ||
            nextState.filters.wineRegion !== this.state.filters.wineRegion ||
            nextState.filters.sakeType !== this.state.filters.sakeType;
    }

    componentDidUpdate() {
        this.loadFoodItems();
    }

    render() {
        const getFilters = this.state.filters;
        const appLayout = localStorage.getItem('app_layout') || 'ementas_digitais';
        const appLayoutConfig = require('../../assets/layout-config.json');
        const currentLayoutConfig = appLayoutConfig[appLayout];

        return (
            <div className={classes[appLayout]}>
                <div className={classes.FoodItems}>
                    {
                        currentLayoutConfig.externalWineFilter &&
                            window.innerHeight <= window.innerWidth &&
                            this.props.filter && this.props.filter.tag &&
                            ['wines', ...wineRegionTags, ...wineTypeTags].indexOf(this.props.filter.tag) >= 0 &&
                            this.state.filters.availableWineTypes ?
                            <div className={classes.WineFilters}>
                                {
                                    this.state.filters.availableWineTypes.map(item => (
                                        <WineItem
                                            onClick={() => this.props.changeFilter(item)}
                                            isSelected={item.tag === this.state.filters.wineType}
                                            key={item.id}
                                            name={item.name}
                                            tag={item.tag} />
                                    ))
                                }
                            </div> : null
                    }
                    {
                        currentLayoutConfig.externalSakeFilter &&
                            window.innerHeight <= window.innerWidth &&
                            this.props.filter && this.props.filter.tag &&
                            ['sake', ...sakeTypeTags].indexOf(this.props.filter.tag) >= 0 &&
                            this.state.filters.availableSakeTypes ?
                            <div className={classes.SakeFilters}>
                                {
                                    this.state.filters.availableSakeTypes.map(item => (
                                        <SakeItem
                                            onClick={() => this.props.changeFilter(item)}
                                            isSelected={item.tag === this.state.filters.sakeType}
                                            key={item.id}
                                            name={item.name}
                                            tag={item.tag} />
                                    ))
                                }
                            </div> : null
                    }
                    {
                        this.state.items ? this.state.items.map(item => {
                            const categories = item.categories.map(category => category.tag);
                            return (
                                <FoodItem
                                    key={item.id}
                                    id={item.id}
                                    name={item.name}
                                    image={item.image}
                                    category={this.props.filter.icon || this.props.filter.tag || categories[0]}
                                    categories={categories}
                                    description={item.description}
                                    nutrition={item.nutrition}
                                    allergies={item.allergies}
                                    price={item.price}
                                    otherPrices={item.other_prices}
                                    filter={getFilters} />
                            )
                        }) : null
                    }
                </div>
            </div>
        );
    }
}

export default FoodItems;
