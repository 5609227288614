import axios from 'axios';

const lang = localStorage.getItem('lang') || 'pt';
const menu = localStorage.getItem('menu');
const apiToken = localStorage.getItem('api_token');

const instance = axios.create({
    baseURL: `https://plataforma.ementasdigitais.pt/api/${lang}/menu/${menu}`,
    headers: {
        'X-Auth-Token': apiToken
    }
});

export default instance;